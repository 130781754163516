<template> 
    <div class="modal fade modal-custom" :id="'modal-'+name" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content modal-custom">
                <div class="modal-body">
                    <div class="main-overlay" style="padding-bottom: 30px;">
                        <div class="therapeutic-title">
                            <h5 class="modal-title">{{title}}</h5>
                            <span class="input-search-close" v-bind:class="[isUserFrench ? 'fr' : '']" data-bs-dismiss="modal" aria-label="Close"></span>
                        </div>
                        <Tree v-if="treeDisplayData.length > 0" ref="my_tree" id="my-tree-id" :custom-options="myCustomOptions" :custom-styles="myCustomStyles" :nodes="treeDisplayData"></Tree>
                        <div v-if="load" class="overlay">
                            <div class="loadding_circle"></div>
                        </div>
                    </div>
                 </div>
                 <div style="margin-top: 12px; text-align: center;position:absolute;bottom:0;width:100%;background:#fff;padding:5px 0"><button type="button" @click="returnvalue" v-bind:class="[isUserFrench ? 'btn-blue' : 'btn-orange']" style="width: 200px">OK</button></div>
            
            </div>
        </div>
    </div>
</template>

<script>

import { Modal } from 'bootstrap'
import Tree from 'vuejs-tree'
import _ from 'lodash' 
import { isUserFrench } from '@/utils'

export default {
    name: "modal_selection",
    components: {
        Tree
    },
    props : {
       title : { 
           type : String,
       },
       list : {
           type : Array,
       },
       name : {
           type : String
       },
       checkedTherapeuticArea : {
           type : Array
       }
       
    },
    data: function() {
        return {
            data_filter : "",
            result : [],
            modal : null,
            treeDisplayData: [],
            checkedMainNodes: []
        }
    },
    mounted() {
        this.modal = new Modal(document.getElementById('modal-'+ this.name));
        this.loadAutocomplete()
        this.initData()
    },
    computed: {
        isUserFrench,
        load () {  
            return this.$store.getters['autocomplete/load']
        },
        therapeutic_tree () {  
            return this.$store.getters['autocomplete/therapeutic_tree']
        },
        myCustomStyles() {
            return {
                tree: {
                    style: {
                        height: "auto",
                        overflowY: "visible",
                        display: "inline-block",
                        textAlign: "left",
                        fontSize: "14px",
                        paddingLeft:"0px"
                    },
                },
                row: {
                    style: {
                        margin: "10px 0"
                    },
                    child: {
                        class: "",
                        style: {
                            height: "initial",
                            display: "flex",
                            alignItems: "flex-start"
                        },
                        active: {
                            style: {
                                fontWeight: 'initial',
                                height: "initial",
                                display: "flex",
                                alignItems: "flex-start"
                            },
                        },
                    },
                },
                rowIndent: {
                    paddingLeft: "15px",
                },
                text: {
                    class: "", // uncomment this line to overwrite the 'capitalize' class, or add a custom class
                    style: {},
                    active: {
                        style: {
                            "font-weight": "bold",
                            color: "#000",
                        },
                    },
                },
            };
        },
        myCustomOptions() {
            return {
                treeEvents: {
                    expanded: {
                        state: false,
                    },
                    collapsed: {
                        state: false,
                    },
                    selected: {
                        state: true,
                    },
                    checked: {
                        state: true,
                        // fn: this.mySelectedFunction
                    },
                },
                events: {
                    expanded: {
                        state: true,
                    },
                    selected: {
                        state: true,
                    },
                    checked: {
                        state: true,
                    },
                    editableName: {
                        state: true,
                        calledEvent: "expanded",
                    },
                },
            };
        },
    },
    methods: {
        loadAutocomplete(){ 
            const keyLoadAutoComplete = []
            if(this.$store.getters['autocomplete/therapeutic_tree'].length === 0){
                keyLoadAutoComplete.push('therapeutic_tree')
            }
            if(keyLoadAutoComplete.length > 0){
                this.$store.dispatch("autocomplete/loadAutocomplete", keyLoadAutoComplete)
            }
        }, 
        returnvalue(){
            this.setCheckedMainNodes(this.treeDisplayData)
            this.$emit("onModal", { key: this.name, value: this.checkedMainNodes });
            this.modal.hide();
        },
        formatData(node){
            let obj = {text: node.index['index_'+this.$i18n.locale], id: node.index.index_id ,state: { checked: false, selected: false, expanded: false }};
            if (!_.isEmpty(node.children) && !('nodes' in obj))
                obj['nodes'] = []

            for (const items of Object.values(node.children)) {
                    if(items.index){
                        obj.nodes.push(this.formatData(items));
                    }
            }
            return obj
        },

        /**
         * Order therapeutic area by alphabetical order (recursively)
         */
        orderTherapeuticArea(data) {

            data.forEach(element => {
                if(element.nodes) {
                    this.orderTherapeuticArea(element.nodes)
                }
            });

            data.sort(function(a, b) {
                var keyA = a.text,
                    keyB = b.text;

                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

            data.forEach(element => {
                if(element.text === 'Other' || element.text === 'Autres')
                    data.push(data.splice(data.indexOf(element), 1)[0]);
            })

            return data
        },

        /** 
         * Handle therapeutical areas checking depends on multiselect tags
        */
        checkTherapeuticArea(datas, checkedTA = null) {
            datas.forEach(element => {
                if((checkedTA && checkedTA.includes(element.text)) || !checkedTA) {
                    element.state.checked = true
                } else {
                    element.state.checked = false
                    
                }

                if(element.nodes) {
                    this.checkTherapeuticArea(element.nodes, (!element.state.checked) ? checkedTA : null)
                }
            })
        },
        
        /**
         * Set checkedMainNodes with checked area only (not include children)
         */
        setCheckedMainNodes(data) {
            data.forEach(item => {
                if(item.state.checked)
                    this.checkedMainNodes.push(item.text)
                else if(!item.state.checked && item.nodes)
                    this.setCheckedMainNodes(item.nodes)
            })
        },
        initData() {
            let tempTree = []
            for (const items of Object.values(this.therapeutic_tree)) {
                let formattedData = this.formatData(items)
                tempTree.push(formattedData)
            }
            this.treeDisplayData = this.orderTherapeuticArea(tempTree)
        }
    },
    watch: {
        therapeutic_tree() {
            this.initData()
        },
        checkedTherapeuticArea: {
            deep: true,
            handler(checkedTA) {
                this.checkTherapeuticArea(this.treeDisplayData, checkedTA)
            }
        }
    }
    
}
</script>

<style scoped>

.therapeutic-area {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    visibility: hidden;
    opacity: 0;
    transition: all .6s;
    background: rgba(0, 0, 0, 0.3);
}

.therapeutic-area.open-box {
    visibility: visible;
    opacity: 1;
}

.therapeutic-title {
    margin-bottom: 10px;
}

.w360{width: 360px;}

.modal-dialog,
.modal-content {
    height: 80%;
}

.modal-dialog{
    max-width: 650px;
}

@media (max-width: 375px){
    .w360{
        width: 90%;
    }
}
</style>
<style>
    #my-tree-id .folder_icon, #my-tree-id .folder_icon_active {display: none}
    #my-tree-id input[type="checkbox"]{
        margin: 0 5px;height: 17px;width: 17px;border: none;border-radius: 5px;
        background-image: url("../../assets/images/checkbox_unchecked.png");
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        background-repeat: no-repeat;
        background-size: 100%;
        }
    #my-tree-id input[type="checkbox"]:checked{
       background-image: url("../../assets/images/checkbox_checked.png");
    }
    #my-tree-id .row_data > span:first-child{
        width: 8px;
        display: block;
    }

    #my-tree-id .row_data > span:last-child{
        margin-top: 0;
        line-height: 18px;
        font-size: 15px;
        width: 90%;
        font-weight: initial !important;
        color: initial !important;
    }

    #my-tree-id .expanded_icon{
    box-shadow: -2px 2px 0 black;
    transform: rotate(-135deg);
    position: relative;
    left: -10px;
    top: -1px;
    width: 8px;
    height: 8px;
    border: none;
    transition: initial;
}

#my-tree-id .expanded_icon.expanded{
    transform: rotate(-45deg);
    left: -5px;
    top: -5px;
}
</style>